<template>
  <div>
    <checkConfirm
      :orderDetail="orderDetail"
      v-model:pageStatus="pageStatus"
      v-if="pageStatus == 'onConfirm'"
      :time="time"
      :score_user_id="score_user_id"
      @notify="updateStatus"
    />
    <CheckQr
      :orderDetail="orderDetail"
      :score_user_id="score_user_id"
      v-model:pageStatus="pageStatus"
      :statusNotify="statusNotify"
      v-else
    ></CheckQr>
  </div>
</template>

<script setup>
import CheckQr from './checkQr.vue'
import checkConfirm from './confirm.vue'
import { ref, onMounted } from 'vue'
import { getExchangeOrderDetail } from '../../service/checkQr.service.js'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

const score_order_id = ref('')
const time = ref(null)
const score_user_id = ref('')
const pageStatus = ref('') // onConfirm: 确认状态 failDate:二维码失效， failCheck未到核销时间，failMore: 多人核销，failOverdue: 已过核销时间, success: 成功
const orderDetail = ref({})
const statusNotify = ref({})

// 获取订单详情
const pageGetorderDetail = async () => {
  const { data } = await getExchangeOrderDetail({
    score_order_id: score_order_id.value,
    score_user_id: score_user_id.value
  })
  orderDetail.value = data || {}
  console.log(data)
}

// 确认核销后回调
const updateStatus = (msg) => {
  console.log('msgmsg', msg)
  statusNotify.value = msg
}

// const orderDetail = async () => {}

onMounted(() => {
  score_order_id.value = route.query.score_order_id || ''
  time.value = (route.query.time - 0) * 1000 || 0
  score_user_id.value = route.query.score_user_id || ''
  pageGetorderDetail()

  // 页面最先判断二维码是否过期
  if (time.value - new Date() < 0) {
    pageStatus.value = 'failDate'
  } else {
    pageStatus.value = 'onConfirm'
  }
})
</script>
